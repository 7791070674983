import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './markdownwrapper.css';

const MarkdownWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

MarkdownWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MarkdownWrapper;
